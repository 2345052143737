import React, { useEffect, useRef, useState } from "react"
import styles from './pdfViewer.module.scss'

type Props = {
  block: Record<string, any>
  scroll: number
}

export default ({ block, scroll }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const calculateZoom = () => {
    if (ref?.current) {
      if (ref.current.offsetWidth > 1000) {
        return 100
      } else {
        return ref.current.offsetWidth / 1000 * 100
      }
    }
  }
  const [zoom, setZoom] = useState(calculateZoom())

  useEffect(() => {
    const setTheZoom = () => {
      setZoom(calculateZoom())
    }
    window.addEventListener('resize', setTheZoom)

    return (() => {
      window.removeEventListener('resize', setTheZoom)
    })
  }, [ref, zoom, block])

  const pdfOps = block.attrs.pdf_url?.includes('#toolbar')
    ? ''
    : '#toolbar=0&navpanes=0&embedded=true'

  return (
    <section className={styles.pdfViewer} ref={ref}>
      <div className={styles.pdfContainer}>
        <div className={styles.pdfWrapper}>
          <iframe
            key={zoom}
            width="560"
            height="349"
            src={`${block.attrs.pdf_url}${pdfOps}&zoom=${zoom}`}
            allowFullScreen>
          </iframe>
        </div>
      </div>
    </section>
  )
}