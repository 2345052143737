import React from "react";
import { NavLink } from "react-router-dom";
import { SearchBar, SearchToggle } from "..";
import { MainLink } from "../../header";
import MegaLink from "./megaLink";
import styles from "./menu.module.scss";

type props = {
  links: MainLink[];
  menuOpen: boolean;
  setMenuOpen: React.Dispatch<boolean>;
  topLinks: LinkProp[];
  searchToggle: "Site" | "Course";
  setSearchToggle: React.Dispatch<"Site" | "Course">;
};

type LinkProp = {
  label: string;
  page: string;
  url: string;
};

export default ({
  links,
  menuOpen,
  setMenuOpen,
  topLinks,
  searchToggle,
  setSearchToggle,
}: props) => {
  const parseLink = (path: string | undefined) => {
    if (path) {
      const parsedPath = path.replace(window.location.origin, "").split("-");
      return parsedPath[0];
    }
    return "";
  };

  const isSubPage = (link: MainLink) =>
    parseLink(link.page) === parseLink(window.location.pathname);

  const ToLink: React.FC<{
    link: LinkProp;
    setMenuOpen: React.Dispatch<boolean>;
  }> = ({ link, setMenuOpen }) => {
    return link.url ? (
      <a
        className={styles.topLink}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => setMenuOpen(false)}
        href={link.url}
        aria-label={`${link.label} Link`}
      >
        {link.label}
      </a>
    ) : (
      <NavLink
        className={styles.topLink}
        activeClassName={styles.active}
        onClick={() => setMenuOpen(false)}
        to={link.page.replace(window.location.origin, "")}
      >
        {link.label}
      </NavLink>
    );
  };


  return (
    <div className={styles.menu + ` ${menuOpen ? styles.open : ""}`}>
      <div className={styles.links}>
        {links &&
          links.map((link, i) => (
            <div key={i}>
              {link.has_megamenu ? (
                <MegaLink {...{ link, menuOpen, setMenuOpen }} />
              ) :  (
                <NavLink
                  className={
                    styles.link + ` ${isSubPage(link) ? styles.active : ""}`
                  }
                  activeClassName={styles.active}
                  to={
                    link?.page
                      ? link.page.replace(window.location.origin, "")
                      : ""
                  }
                  onClick={() => setMenuOpen(false)}
                >
                  {link.label}
                </NavLink>
              )}
            </div>
          ))}
        {topLinks &&
          topLinks.map((link, i) => (
            <ToLink key={i} {...{ link, setMenuOpen }} />
          ))}
      </div>
      <div className={styles.top}>
        <SearchToggle {...{ searchToggle, setSearchToggle }} />
        <SearchBar {...{ setMenuOpen, searchToggle }} />
      </div>
    </div>
  );
};
